import { useCookies } from './useCookies';

export const useAgent = () => {
  const { getCookie } = useCookies();
  const vkzCookieObject = getCookie('vkz');
  const vkz = vkzCookieObject && JSON.parse(vkzCookieObject).vkz;

  let isAgent = false;
  if (
    vkz &&
    vkz.match(
      /(BCOUT|CCNESU|CCOUT|CCSEMA|IVDVIN|IVDVPK|IVFH|IVKPPK|IVPKBK|IVRT|IVSH|PKAM|PKISV|PKKD){1}([a-zA-Z0-9]{3,6})\b/,
    )
  ) {
    isAgent = true;
  }

  return { isAgent, vkz };
};
