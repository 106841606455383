import React from 'react';
import dynamic from 'next/dynamic';
import useSWR from 'swr/immutable';
import cx from 'classnames';
import {
  Wrapper,
  Flag,
  Separator,
  Spacer,
  Text,
  LoadingSpinner,
  Footnote,
} from 'dss-ui-library';
import { DynamicContentProps } from '@ncs-frontend-monorepo/content-library';
import { Footnote as IFootnote } from '../../interfaces/template';
import {
  contentApiURL,
  createShortDescriptionByPlan,
  prepareFootnotes,
} from '../../utils';
import { SitemapEntry } from '../../interfaces';
import { useModalContext } from '../ModalContext';
import styles from './PlanColumn.module.scss';
import { ProductDescriptionText } from '../PlanElements/ProductDescription';
import { TemplatePageLink } from '../PlanElements/TemplatePageLink';
import { BESTSELLER_TEXT, TOPPRICETEXT } from '../../constants';
import { getFormattedPlanName } from '../../utils/getFormattedPlanName';
import { ChannelList } from '../PlanElements/ChannelList';
import { TV_LONG_DESCRIPTIONS } from '../../constants/tv';
import { usePlanState } from '../../hooks/usePlanState';
import { TVButtons } from '../PlanElements/TVButtons';
import { fetcher } from '@ncs-frontend-monorepo/utils';
import { Pricing, Product } from '@ncs-frontend-monorepo/order';

const PlanInfoModal = dynamic(
  () =>
    import('../Modals/PlanInfoModal').then(
      ({ PlanInfoModal }) => PlanInfoModal,
    ),
  {
    ssr: false,
    loading: () => <LoadingSpinner theme="blue" />,
  },
);

interface PlanColumnTVProps {
  plan: Product & {
    templatePageLink?: SitemapEntry['path'];
    isBestseller?: boolean;
    isTopprice?: boolean;
  };
  onAvailabilityCheckClick?: (plan: Product, tryToCheckout: boolean) => void;
  footnotes?: IFootnote[];
  mode?: 'default' | 'mini';
}

const PlanColumnTV: React.FC<PlanColumnTVProps> = ({
  plan,
  onAvailabilityCheckClick,
  footnotes = [],
  mode = 'default',
}) => {
  const { handleModal } = useModalContext();
  const urlDescription = `${contentApiURL()}pksite-infomodal-${
    plan.id
  }-description`;
  const urlTiles = `${contentApiURL()}pksite-infomodal-${plan.id}-tiles`;
  const { data: description } = useSWR<DynamicContentProps>(
    urlDescription,
    fetcher,
  );
  const { data: tiles } = useSWR<DynamicContentProps>(urlTiles, fetcher);
  const { isDisabled, isPlanStateChecked, hasNetTVInternet } = usePlanState({
    plan,
  });
  const shortDescription = createShortDescriptionByPlan(plan);
  const formattedPlanName = getFormattedPlanName(plan);

  const highlightFlags = [
    plan.isBestseller && BESTSELLER_TEXT,
    plan.isTopprice && TOPPRICETEXT,
  ].filter((e) => e);

  const planElementSpacerProps = {
    x: 3,
    y: 2,
    block: true,
    padding: true,
  };

  const getFootnote = prepareFootnotes(footnotes);
  const footnotePrice = getFootnote('price');

  const handleClick = () => {
    onAvailabilityCheckClick?.(plan, true);
  };

  const handleTooltipClick = () => {
    handleModal({
      content: (
        <PlanInfoModal
          plan={plan}
          description={description?.content}
          tiles={tiles?.content}
        />
      ),
      e2e: `${plan.id}-details`,
    });
  };

  return (
    <div className={styles.planColumn} data-e2e={`column-${plan.id}`}>
      {mode === 'default' && (
        <>
          {highlightFlags.length > 0 && (
            <Flag color="red" className={styles.flag}>
              {highlightFlags[0]}
            </Flag>
          )}
          <Wrapper
            className={cx(styles.planColumnInfoBox, {
              [styles.isDisabled]: isDisabled,
            })}
          >
            <Text appearance="t2" color="black" block e2e="planColumn-name">
              {formattedPlanName}
            </Text>
            <ProductDescriptionText items={shortDescription} animated={false} />
            <Spacer b={1}>
              <Text element="h3" appearance="t6" color="black">
                {TV_LONG_DESCRIPTIONS?.[plan.id]}
              </Text>
            </Spacer>
            <ChannelList templateId={plan.id} visibleItems={8} />
            <Spacer b={2} block>
              <Separator height={4} color="superlight-grey" />
            </Spacer>
            <Pricing
              plan={plan}
              e2e={`planColumn-${plan.id}`}
              onTooltipClick={handleTooltipClick}
              priceTextInfo="mtl."
              footnote={
                footnotePrice && <Footnote number={footnotePrice.number} />
              }
              isLongTerm
              wordBreakOnMobile
            />
          </Wrapper>
          <Spacer t={2} b={1} x={2} block>
            <TVButtons
              isDisabled={isDisabled}
              isAddressChecked={isPlanStateChecked}
              hasNetTVInternet={hasNetTVInternet}
              handleClick={handleClick}
            />
          </Spacer>
          {plan.templatePageLink && (
            <Spacer {...planElementSpacerProps}>
              <TemplatePageLink url={plan.templatePageLink} />
            </Spacer>
          )}
        </>
      )}
    </div>
  );
};

export default PlanColumnTV;
