import {
  Grid,
  IconChevron,
  LoadingSpinner,
  Spacer,
  useBreakpoints,
} from 'dss-ui-library';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  DEFAULT_PREMIUM_MIN_BANDWIDTH,
  FALLBACK_BANDWITH,
  MAX_VISIBLE_PLAN,
  MAX_VISIBLE_PLAN_FTTH,
  YOUNG_GROUPS,
} from '../../constants';
import {
  iadBasicRecommendationPlan,
  iadPremiumRecommendationPlan,
} from '../../constants/contentBlocks';
import { TV_GROUPS } from '../../constants/tv';
import { useAgent } from '../../hooks/useAgent';
import { useAvailabilityModal } from '../../hooks/useAvailabilityModal';
import { useFTTHCheckout } from '../../hooks/useFTTHCheckout';
import { useScrollSlider } from '../../hooks/useScrollSlider';
import { DefaultRuntime } from '../../interfaces';
import { ProductModifiers } from '../../interfaces/product';
import { DynamicPlan, Footnote, ProductMap } from '../../interfaces/template';
import { getDynamicPlans } from '../../utils';
import { isBandwidthAvailable } from '../../utils/ftthPreMarketing';
import {
  getPlan,
  getPlanParts,
  getProductForPlanColumn,
  getTemplateId,
} from '../../utils/plan';
import { ViewType } from '../AvailabilityCheck/Availability';
import { AvailabilityTypes, Portfolio } from '../AvailabilityCheck/Context';
import {
  AvailabilityEmbedded,
  EmbeddedTypes,
} from '../AvailabilityCheck/Embedded';
import { useModalContext } from '../ModalContext';
import { useOrderContext } from '../OrderContext';
import { ProductContext } from '../ProductContext';
import PlanColumn from './PlanColumn';
import PlanColumnTV from './PlanColumnTV';
import styles from './PlanColumns.module.scss';
import SlideIndicator from './SlideIndicator';
import { Item as TabItem, Tabs } from './Tabs';
import {
  DataLayerEvents,
  EventAvailabilityOpen,
} from '../../interfaces/tracking';
import { pushDataLayer } from '../../utils/dataLayer';
import {
  ProductId,
  PropertyOwnerRole,
  ResidentialUnit,
  useCheckoutSelectionStore,
  Product,
} from '@ncs-frontend-monorepo/order';
import { useScrollTo } from '@ncs-frontend-monorepo/utils';

const FTTHPresalesAvailability = dynamic(
  () =>
    import(
      '../AvailabilityCheck/FTTHPresalesAvailability/FTTHPresalesAvailability'
    ).then(({ FTTHPresalesAvailability }) => FTTHPresalesAvailability),
  {
    ssr: false,
    loading: () => <LoadingSpinner theme="blue" />,
  },
);

const FTTHPresalesPodSelection = dynamic(
  () =>
    import('../FTTH/Order/PODData/PODSelection').then(
      ({ PODSelection }) => PODSelection,
    ),
  {
    ssr: false,
    loading: () => <LoadingSpinner theme="blue" />,
  },
);

const ProductProvider = dynamic(() =>
  import('../ProductContext').then(({ ProductProvider }) => ProductProvider),
);

export interface PlanColumnProps extends Product {
  isLongTerm?: boolean;
  isBestseller?: boolean;
  isTopprice?: boolean;
  isHighlighted?: boolean;
  templatePageId?: number;
  isFTTHPrePresale?: boolean;
  isInCheckout?: boolean;
}

export interface PlanColumnsProps {
  defaultRuntime?: DefaultRuntime;
  runtimeInfo?: string;
  plans: PlanColumnProps[];
  footnotes?: Footnote[];
  dynamicPlans?: ProductMap<DynamicPlan>;
  isFTTHPrePresale?: boolean;
  uncheckToggleOptions?: boolean;
  isInCheckout?: boolean;
  portfolio: Portfolio;
  withoutCheck: boolean;
  currentTemplateId?: ProductId;
  onPlanSelection?: (templateId: ProductId) => void;
  handleOfferToggle?: () => void;
  kundenweltURL?: string;
}

const PlanColumns: FC<PlanColumnsProps> = ({
  defaultRuntime = DefaultRuntime.Default,
  plans = [],
  footnotes = [],
  dynamicPlans = null,
  isFTTHPrePresale = false,
  uncheckToggleOptions = false,
  isInCheckout = false,
  currentTemplateId,
  portfolio,
  withoutCheck = false,
  onPlanSelection,
  kundenweltURL,
}) => {
  const { isAgent } = useAgent();
  const { isXS } = useBreakpoints();
  const { openModal } = useAvailabilityModal();
  const router = useRouter();
  const { handleModal } = useModalContext();
  const { goToFTTHCheckout } = useFTTHCheckout();
  const planRef = useRef<HTMLElement>(null);
  const scrollToPlan = useScrollTo(planRef, { timeout: 300 });
  const {
    promotions,
    maxAvailableDownload,
    plannedAvailability,
    ftthPresalesInformation,
    setAddress,
  } = useOrderContext();
  const { update } = useCheckoutSelectionStore();
  const hasFallback = FALLBACK_BANDWITH.includes(maxAvailableDownload);
  const [visiblePlans, setVisiblePlans] = useState<PlanColumnProps[]>(plans);
  const [defaultDynamicPlans, setDefaultDynamicPlans] = useState(dynamicPlans);
  const [isLongTerm, setLongTerm] = useState(
    defaultRuntime === DefaultRuntime.Default,
  );
  const isYoung = visiblePlans.some((plan) =>
    YOUNG_GROUPS.includes(plan.group),
  );
  const disableShortTerm = visiblePlans.some(
    (plan) => !plan.isWithoutRuntimeAvailable,
  );

  const getHighlightedPlan = (): number => {
    // disable scrolling to highlighted plan.
    // Selection will be handled in a useEffect
    if (isInCheckout) {
      return;
    }

    const maxDownload =
      isFTTHPrePresale && ftthPresalesInformation
        ? ftthPresalesInformation.maxAvailableDownload
        : maxAvailableDownload;

    const maxVisibleDownload = isFTTHPrePresale
      ? MAX_VISIBLE_PLAN_FTTH
      : MAX_VISIBLE_PLAN;

    return maxDownload > 0 && maxDownload < maxVisibleDownload
      ? visiblePlans.findIndex(
          (plan) => plan.internet?.download === maxDownload,
        )
      : visiblePlans.findIndex((plan) => plan.isHighlighted);
  };
  const columnsRef = useRef(null);
  const { liveValues, scrollToSlide, manualScroll } = useScrollSlider(
    columnsRef,
    getHighlightedPlan(),
  );
  const plannedMaxAvailableDownload = plannedAvailability?.maxAvailableDownload;

  const handleOfferToggle = async () => {
    setLongTerm(!isLongTerm);
  };

  const handleFtthCheckout = async (plan: Product) => {
    const couldCheckOut = await goToFTTHCheckout(plan.id);

    if (!couldCheckOut) {
      console.error('ERROR during redirect to FTTH-Checkout.');
    }
  };

  const handleClickForFTTH = async (plan: Product) => {
    const handleUserSelectionSubmit = async (
      propertyOwnerRole: PropertyOwnerRole,
      residentialUnit: ResidentialUnit,
    ) => {
      update({ propertyOwnerRole, residentialUnit });
      await handleFtthCheckout(plan);
    };

    if (
      isBandwidthAvailable(plan.internet?.download, ftthPresalesInformation)
    ) {
      if (onPlanSelection) {
        onPlanSelection(plan.id);
        return;
      }

      const event: EventAvailabilityOpen = {
        event: DataLayerEvents.Open,
        checkStatus: 'bereits-geprueft',
        checkSource: AvailabilityTypes.Expansion,
        requestedDownload: plan.internet?.download,
        templateId: plan.id,
      };
      pushDataLayer(event);

      handleModal({
        content: (
          <FTTHPresalesPodSelection onSubmit={handleUserSelectionSubmit} />
        ),
        e2e: `${plan.id}-select-pod`,
      });
    } else {
      handleModal({
        content: (
          <FTTHPresalesAvailability
            templateId={plan.id}
            requestedDownload={plan.internet?.download}
            availablePlans={plans}
            portfolio={portfolio}
            requestedPlanName={plan.name}
          />
        ),
        e2e: `${plan.id}-availability-check`,
      });
    }
  };

  const handleClick = (plan: Product, tryToCheckout = false) => {
    // store plans page url the user came from
    if (!isInCheckout) {
      update({ plansUrl: router.asPath });
    }

    if (isFTTHPrePresale) {
      handleClickForFTTH(plan);
      return;
    }

    openModal({
      plan,
      isLongTerm,
      portfolio,
      availabilityType: TV_GROUPS.includes(plan.group)
        ? AvailabilityTypes.TvPlan
        : AvailabilityTypes.NetspeedPlan,
      ...(tryToCheckout ? { desiredEntrypoint: ViewType.CHECKOUT } : {}),
      e2e: `${plan.id}-availability-check`,
      kundenweltURL,
    });
  };

  const planColumnProps = (
    plan: PlanColumnProps,
    { modifier, toggleNetTV, togglePhone }: ProductContext,
  ) => {
    const isSelected =
      isInCheckout &&
      ((defaultDynamicPlans &&
        Object.values(defaultDynamicPlans[plan.id]).some((p) => {
          return p.id === currentTemplateId;
        })) ||
        currentTemplateId === plan.id);

    return {
      plan,
      isLongTerm: plan.isWithoutRuntimeAvailable ? isLongTerm : true,
      isSelected,
      footnotes,
      ...(defaultDynamicPlans?.[plan.id] && {
        dynamicPlan: defaultDynamicPlans[plan.id],
        modifier,
        onNetTvToggle: toggleNetTV,
        onPhoneToggle: togglePhone,
      }),
      onAvailabilityCheckClick: handleClick,
      handleOfferToggle,
      disableShortTerm,
      portfolio,
    };
  };

  useEffect(() => {
    if (isYoung) {
      scrollToSlide(getHighlightedPlan());
    }
  }, [isYoung]);

  // Reset address for agents visiting the FTTH plans page
  useEffect(() => {
    if (isAgent && isFTTHPrePresale && !isInCheckout) {
      setAddress(null);
    }
  }, [isAgent]);

  useEffect(() => {
    if (maxAvailableDownload < MAX_VISIBLE_PLAN) {
      scrollToSlide(getHighlightedPlan());
    }
  }, [maxAvailableDownload]);

  useEffect(() => {
    //handle FTTH
    if (isInCheckout) {
      const updatedPlans = plans.filter((plan) => {
        return (
          plan.internet?.download >=
            ftthPresalesInformation.minAvailableDownload &&
          plan.internet?.download <=
            ftthPresalesInformation.maxAvailableDownload
        );
      });
      setVisiblePlans(updatedPlans);
    }
    if (
      isFTTHPrePresale &&
      ftthPresalesInformation?.maxAvailableDownload < MAX_VISIBLE_PLAN_FTTH
    ) {
      scrollToSlide(getHighlightedPlan());
    }
  }, [ftthPresalesInformation, isInCheckout, isFTTHPrePresale]);

  useEffect(() => {
    // on fiber portfolio no fallback is given
    if (portfolio === 'FIBER') {
      return;
    }

    if (hasFallback && promotions?.length > 0) {
      const { bandwidth: _bandwidth, ...planParts } = getPlanParts(plans[0].id);
      const fallbackId = getTemplateId({
        bandwidth: maxAvailableDownload,
        ...planParts,
      });

      const technology = promotions.filter(
        (promotion) => promotion.maxDownload === maxAvailableDownload,
      )?.[0]?.technology;

      getProductForPlanColumn({
        templateId: fallbackId,
        technology,
        iadSlug:
          maxAvailableDownload >= DEFAULT_PREMIUM_MIN_BANDWIDTH
            ? iadPremiumRecommendationPlan
            : iadBasicRecommendationPlan,
        bandwidth: maxAvailableDownload,
        portfolio: 'CLASSIC',
      }).then((fallbackPlan) => {
        const updatedPlans = [...plans, ...[fallbackPlan]].sort((a, b) =>
          a?.internet?.download > b?.internet?.download ? 1 : -1,
        );

        setVisiblePlans(updatedPlans);

        if (dynamicPlans) {
          if (!Object.keys(defaultDynamicPlans).includes(fallbackPlan.id)) {
            getDynamicPlans({
              basePlans: [fallbackPlan.id],
              modifiers: { togglePhone: true, toggleNetTv: true },
              technology,
            }).then((plans) => {
              const updatedDynamicPlans = {
                ...dynamicPlans,
                ...plans,
              };
              setDefaultDynamicPlans(updatedDynamicPlans);
            });
          }
        }
      });
    } else {
      setVisiblePlans(plans);
    }
  }, [hasFallback, promotions, plans]);

  useEffect(() => {
    // if plans are rendered in checkout,
    // select the item in the basket
    if (isInCheckout && currentTemplateId) {
      const selectedPlanIndex = visiblePlans.findIndex(
        (plan) =>
          plan.id == currentTemplateId ||
          (dynamicPlans &&
            Object.values(dynamicPlans[plan.id]).some((p) => {
              return p.id === currentTemplateId;
            })),
      );

      if (selectedPlanIndex > -1) {
        // if viewport is large, shift index by one,
        // so that larger plans are visible as upsell
        const shiftedPlanIndex = isXS
          ? selectedPlanIndex
          : Math.max(0, selectedPlanIndex - 1);
        scrollToSlide(shiftedPlanIndex);
      }
    }
  }, [isXS]);

  useEffect(() => {
    if (!router.isReady) return;

    const productIdToOrder = router.query.order;
    if (!productIdToOrder && typeof productIdToOrder != 'string') return;

    const planToCheckout = getPlan(
      productIdToOrder as string,
      visiblePlans,
      dynamicPlans,
    );

    if (!planToCheckout) return;
    handleClick(planToCheckout, true);
  }, [router, visiblePlans]);

  const Columns = ({
    modifier,
    setModifier,
    toggleNetTV,
    togglePhone,
  }: ProductContext) => {
    const tabItems = useMemo<TabItem[]>(() => {
      return visiblePlans.map((plan) => {
        const displayedPlan = modifier
          ? defaultDynamicPlans?.[plan.id]?.[modifier] || plan
          : plan;
        const download = displayedPlan.internet?.download;
        const longTermPrice =
          displayedPlan.pricing.monthly?.discountedPrices?.length > 0
            ? displayedPlan.pricing.monthly?.discountedPrices[0].price
            : displayedPlan.pricing.monthly.price;

        return {
          headline: TV_GROUPS.includes(displayedPlan.group)
            ? displayedPlan.name
            : `${displayedPlan.internet?.download} Mbit/s`,
          price: isLongTerm
            ? longTermPrice
            : displayedPlan.pricing.monthly.price,
          isBestseller: plan.isBestseller,
          isTopprice: plan.isTopprice,
          presaleDate:
            download > maxAvailableDownload &&
            download > plannedMaxAvailableDownload
              ? `Ab ${plannedAvailability.plannedAvailabilityDateDescription}`
              : '',
        };
      });
    }, [
      visiblePlans,
      defaultDynamicPlans,
      modifier,
      maxAvailableDownload,
      plannedMaxAvailableDownload,
      isLongTerm,
    ]);

    // For FTTH Checkout only:
    // set modifier based on current selected template ID
    useEffect(() => {
      if (isInCheckout && dynamicPlans && currentTemplateId) {
        Object.values(dynamicPlans).forEach((dynamicPlan) => {
          Object.keys(dynamicPlan).some((planModifier) => {
            if (dynamicPlan[planModifier].id === currentTemplateId) {
              setModifier(planModifier as ProductModifiers);
              return true;
            }
          });
        });
      }
    }, []);

    useEffect(() => {
      const hash = window.location.hash.substring(1);
      if (
        [
          ProductModifiers.withoutModifier,
          ProductModifiers.withoutPhone,
          ProductModifiers.withoutTv,
          ProductModifiers.withoutAll,
        ].includes(hash as ProductModifiers)
      ) {
        setModifier(hash as ProductModifiers);
        !isInCheckout && scrollToPlan();
      } else if (!isInCheckout && uncheckToggleOptions) {
        setModifier(ProductModifiers.withoutAll);
      }
    }, [typeof window != 'undefined' && window.location.hash]);

    const templateIdForAvailability = useMemo(() => {
      const defaultPlan = visiblePlans?.[0]?.id;

      return defaultDynamicPlans
        ? defaultDynamicPlans?.[defaultPlan]?.[modifier]?.id || defaultPlan
        : defaultPlan;
    }, [modifier, defaultDynamicPlans, visiblePlans?.[0]?.id]);

    return (
      <>
        {!(isFTTHPrePresale || withoutCheck) && (
          <Spacer b={5} block>
            <AvailabilityEmbedded
              type={EmbeddedTypes.NETSPEED_CHECK}
              params={{ portfolio, fullWidth: true }}
              isSmall
              templateId={templateIdForAvailability}
            />
          </Spacer>
        )}
        <section data-name="plans" ref={planRef} id="plans">
          <Grid fullWidth className={styles.grid}>
            <Grid.Row>
              <Grid.Column hideS className={styles.tabsWrapper}>
                <Tabs
                  selected={liveValues.singleVisibleSlideIndex}
                  onSelected={scrollToSlide}
                  items={tabItems}
                />
                <Spacer t={3} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={styles.arrows}>
              {liveValues.singleVisibleSlideIndex > 0 && (
                <IconChevron
                  className={styles.arrowLeft}
                  width={48}
                  height={48}
                  rotation="left"
                  color="white"
                  onClick={() => manualScroll('prev')}
                  e2e="chevron-prev"
                  small
                />
              )}
              {liveValues.visibleSlidesIndices[
                liveValues.visibleSlidesIndices.length - 1
              ] +
                1 <
                liveValues.slideVisibilityMatrix.length && (
                <IconChevron
                  className={styles.arrowRight}
                  width={48}
                  height={48}
                  rotation="right"
                  color="white"
                  onClick={() => manualScroll('next')}
                  e2e="chevron-next"
                  small
                />
              )}
            </Grid.Row>
            <Grid.Row className={styles.row} ref={columnsRef}>
              {visiblePlans.map((plan) => (
                <Grid.Column
                  key={plan.id}
                  s={5}
                  m={4}
                  className={styles.column}
                >
                  {TV_GROUPS.includes(plan.group) ? (
                    <PlanColumnTV
                      plan={plan}
                      footnotes={footnotes}
                      onAvailabilityCheckClick={handleClick}
                    />
                  ) : (
                    <PlanColumn
                      {...planColumnProps(plan, {
                        modifier,
                        toggleNetTV,
                        togglePhone,
                      })}
                    />
                  )}
                </Grid.Column>
              ))}
            </Grid.Row>
            {liveValues.invisibleSlidesIndices.length > 0 && (
              <Grid.Row>
                <Grid.Column hCenter>
                  <SlideIndicator
                    slides={liveValues.slideVisibilityMatrix}
                    onItemClick={(index) => scrollToSlide(index)}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </section>
      </>
    );
  };

  return (
    <ProductProvider
      value={{
        nettvEnabled: !isYoung,
        telephonyEnabled: !isYoung,
        toggledDetail: [],
      }}
    >
      {Columns}
    </ProductProvider>
  );
};

export default PlanColumns;
