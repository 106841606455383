import cx from 'classnames';
import {
  Button,
  Flag,
  Footnote,
  IconCheck,
  LoadingSpinner,
  Separator,
  Spacer,
  Text,
  ToggleRuntime,
  Wrapper,
} from 'dss-ui-library';
import { DynamicContentProps } from '@ncs-frontend-monorepo/content-library';
import dynamic from 'next/dynamic';
import React, { ReactNode, useEffect } from 'react';
import useSWR from 'swr/immutable';
import { BESTSELLER_TEXT, TOPPRICETEXT } from '../../constants';
import { usePlanState } from '../../hooks/usePlanState';
import { usePlans } from '../../hooks/usePlans';
import { ProductInfo, SitemapEntry } from '../../interfaces';
import { ProductModifiers } from '../../interfaces/product';
import { DynamicPlan, Footnote as IFootnote } from '../../interfaces/template';
import {
  contentApiURL,
  getUploadFootnoteKey,
  prepareFootnotes,
} from '../../utils';
import { toCamelCase } from '../../utils/helpers';
import { Portfolio } from '../AvailabilityCheck/Context';
import { useModalContext } from '../ModalContext';
import { useOrderContext } from '../OrderContext';
import { IadDevice } from '../PlanElements/IadDevice';
import { InternetDescription } from '../PlanElements/InternetDescription';
import { NetTVDescription } from '../PlanElements/NetTVDescription';
import { NotAvailableInfo } from '../PlanElements/NotAvailableInfo';
import { ProductName } from '../PlanElements/ProductName';
import { TelephonyDescription } from '../PlanElements/TelephonyDescription';
import { TemplatePageLink } from '../PlanElements/TemplatePageLink';
import { TreeInfo } from '../PlanElements/TreeInfo';
import { NetServiceInfo } from '../PlanElements/NetServiceInfo';
import { ProductContext } from '../ProductContext';
import styles from './PlanColumn.module.scss';
import { fetcher } from '@ncs-frontend-monorepo/utils';
import {
  AvailabilityStatus,
  ExpansionStatus,
  Fallback,
} from '@ncs-frontend-monorepo/availability';
import { Product, ProductGroups, Pricing } from '@ncs-frontend-monorepo/order';

const PlanInfoModal = dynamic(
  () =>
    import('../Modals/PlanInfoModal').then(
      ({ PlanInfoModal }) => PlanInfoModal,
    ),
  {
    ssr: false,
    loading: () => <LoadingSpinner theme="blue" />,
  },
);

interface PlanColumnProps {
  plan: Product & {
    templatePageLink?: SitemapEntry['path'];
    isBestseller?: boolean;
    isHighlighted?: boolean;
    isTopprice?: boolean;
    isFTTHPrePresale?: boolean;
    isInCheckout?: boolean;
    savingInfo?: string;
    iadDeviceInfo?: ProductInfo;
  };
  dynamicPlan?: DynamicPlan;
  onAvailabilityCheckClick?: (plan: Product, tryToCheckout: boolean) => void;
  isLongTerm?: boolean;
  footnotes?: IFootnote[];
  onExpandDescription?: () => void;
  onPhoneToggle?: () => void;
  onNetTvToggle?: () => void;
  modifier?: ProductContext['modifier'];
  mode?: 'default' | 'mini';
  isSelected?: boolean;
  handleOfferToggle?: () => void;
  disableShortTerm?: boolean;
  runtimeInfo?: string;
  portfolio?: Portfolio;
}

const PlanColumn: React.FC<PlanColumnProps> = ({
  plan,
  onAvailabilityCheckClick,
  isLongTerm = true,
  footnotes = [],
  dynamicPlan,
  onNetTvToggle,
  onPhoneToggle,
  modifier,
  mode = 'default',
  isSelected = false,
  handleOfferToggle,
  disableShortTerm,
  portfolio = 'FIBER',
}) => {
  const { isFTTHPrePresale, isInCheckout } = plan;
  const {
    setAddress,
    maxAvailableDownload,
    plannedAvailability,
    ftthPresalesInformation,
    fallback,
  } = useOrderContext();
  const {
    isDisabled,
    isPresale,
    isPlanStateChecked,
    portfolioMismatch,
    availablePortfolio,
  } = usePlanState({
    plan,
    isFTTHPresale: isFTTHPrePresale,
    portfolio,
  });
  const { handleModal } = useModalContext();
  const { usedPlan } = usePlans({
    plan,
    dynamicPlan,
    modifier,
    skipUpdate: isFTTHPrePresale,
    portfolio,
  });
  const urlDescription = `${contentApiURL()}pksite-infomodal-${
    plan.id
  }-description`;
  const urlTiles = `${contentApiURL()}pksite-infomodal-${plan.id}-tiles`;
  const { data: description } = useSWR<DynamicContentProps>(
    urlDescription,
    fetcher,
  );
  const { data: tiles } = useSWR<DynamicContentProps>(urlTiles, fetcher);

  const highlightFlags = [
    isPresale && (
      <>
        <Text appearance="t5">Ab</Text>{' '}
        {plannedAvailability?.plannedAvailabilityDateDescription}
      </>
    ),
    plan.isBestseller && BESTSELLER_TEXT,
    plan.isTopprice && TOPPRICETEXT,
  ].filter((e) => e);

  const planElementSpacerProps = {
    x: 2,
    b: 0,
    t: 2,
    block: true,
    padding: true,
    className: cx(isDisabled && styles.disabled),
  };

  const getFootnote = prepareFootnotes(footnotes);
  const footnoteTelefonFlat = getFootnote('telefonflat');
  const footnoteNetTV = getFootnote('nettv');
  const footnoteUpload = getFootnote(getUploadFootnoteKey(usedPlan));
  const footnotePrice = getFootnote('price');
  const footnoteRouterFlag = getFootnote('router-flag');

  const primaryButtonE2E = (() => {
    if (isFTTHPrePresale) {
      return isPlanStateChecked ? 'preorder-ftth' : 'check-ftth-availability';
    } else {
      return isPlanStateChecked
        ? isPresale
          ? `preorder-now-${plannedAvailability?.plannedAvailabilityDateDescription?.replaceAll(
              ' ',
              '',
            )}`
          : 'order-now'
        : 'check-availability';
    }
  })();
  const planColumnE2E = (() => {
    const baseE2ETag = `column-${usedPlan.id}`;
    if (
      isFTTHPrePresale &&
      ftthPresalesInformation &&
      !ftthPresalesInformation.isOffered &&
      ftthPresalesInformation.alternativeProductAvailability
    ) {
      const availabilityStatus =
        ftthPresalesInformation.alternativeProductAvailability
          .toString()
          .toLowerCase()
          .replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());

      return `${baseE2ETag}-${availabilityStatus}`;
    }

    const expansionState =
      ftthPresalesInformation && ftthPresalesInformation.status
        ? `-notAvailable-${toCamelCase(ftthPresalesInformation.status)}`
        : '-notAvailable';

    return `${baseE2ETag}${
      isDisabled ? `${expansionState}` : isPlanStateChecked ? '-available' : ''
    }`;
  })();

  const handleClick = () => {
    onAvailabilityCheckClick?.(usedPlan, isPlanStateChecked);
  };

  // effect to emit new plan after modifier changes
  useEffect(() => {
    if (isInCheckout && isSelected) {
      handleClick();
    }
  }, [usedPlan]);

  const PrimaryButton = () => {
    let label: ReactNode = isPlanStateChecked
      ? isPresale || isFTTHPrePresale
        ? 'Jetzt vorbestellen'
        : 'Jetzt bestellen'
      : 'Adresse prüfen';

    if (isInCheckout) {
      label = isSelected ? (
        <>
          <IconCheck
            className={styles.primaryButtonIcon}
            width={19}
            height={17}
          />
          Tarif ausgewählt
        </>
      ) : (
        'Tarif auswählen'
      );
    }
    return (
      <Button
        color={isPlanStateChecked ? 'red' : 'blue'}
        e2e={primaryButtonE2E}
        variant={isPlanStateChecked ? 'primary' : 'secondary'}
        fullWidth
        onClick={handleClick}
        disabled={isSelected}
      >
        {label}
      </Button>
    );
  };

  const handleTooltipClick = () => {
    handleModal({
      content: (
        <PlanInfoModal
          plan={usedPlan}
          description={description?.content}
          tiles={tiles?.content}
        />
      ),
      e2e: `${usedPlan.id}-details`,
    });
  };

  return (
    <>
      {highlightFlags.length > 0 && !(isPresale && portfolioMismatch) && (
        <Flag
          color="red"
          className={cx(styles.flag, isDisabled && styles.isDisabledFlag)}
        >
          {highlightFlags[0]}
        </Flag>
      )}
      <div
        className={cx(
          styles.planColumn,
          highlightFlags.length <= 0 && styles.planColumnTopSpace,
          plan.isHighlighted && styles.isHighlighted,
        )}
        data-e2e={planColumnE2E}
        data-tracking-plan-bandwidth={plan?.internet?.download}
      >
        {mode === 'default' && (
          <>
            <Wrapper
              className={cx(
                isDisabled && styles.disabled,
                isDisabled && styles.isDisabled,
                styles.planColumnInfoBox,
              )}
            >
              <ProductName plan={usedPlan} />
              <Spacer y={2} block>
                <Separator color="superlight-grey" height={4} />
              </Spacer>
              {isFTTHPrePresale && <Spacer b={2} block />}

              <Pricing
                plan={usedPlan}
                e2e={`planColumn-${usedPlan.id}`}
                onTooltipClick={handleTooltipClick}
                priceTextInfo="mtl."
                footnote={
                  footnotePrice && <Footnote number={footnotePrice.number} />
                }
                isLongTerm={isLongTerm}
                wordBreakOnMobile
              />

              {!disableShortTerm && (
                <Spacer t={1}>
                  <ToggleRuntime
                    name="runtime-toggle"
                    label="Monate Laufzeit"
                    e2e="toggle-product-runtime"
                    isLongTerm={isLongTerm}
                    onChange={handleOfferToggle}
                    disabled={disableShortTerm}
                    className={styles.runtimeToggle}
                  />
                </Spacer>
              )}
            </Wrapper>
            <Spacer x={2} block>
              {isDisabled && ftthPresalesInformation ? (
                <NotAvailableInfo
                  availablePortfolio={availablePortfolio}
                  modifier={modifier}
                  isFallback={
                    isFTTHPrePresale && fallback === Fallback.TECHNOLOGY
                  }
                  availabilityStatus={
                    (isFTTHPrePresale || isPresale) &&
                    (maxAvailableDownload >= usedPlan.internet.download ||
                      plannedAvailability?.maxAvailableDownload >=
                        usedPlan.internet.download) &&
                    ftthPresalesInformation.alternativeProductAvailability
                      ? ftthPresalesInformation.alternativeProductAvailability
                      : AvailabilityStatus.NOT_AVAILABLE
                  }
                  preSalesStatus={
                    !isFTTHPrePresale
                      ? ftthPresalesInformation?.status
                      : ExpansionStatus.NOT_PLANNED
                  }
                  landingPage={ftthPresalesInformation?.landingPage}
                  planBandwidth={usedPlan.internet.download}
                  portfolioMismatch={portfolioMismatch}
                  templateId={usedPlan.id}
                  planGroup={plan.group}
                  onChangeClick={() => {
                    setAddress(null);
                    onAvailabilityCheckClick(usedPlan, false);
                  }}
                />
              ) : (
                <PrimaryButton />
              )}
            </Spacer>
            {plan.internet && (
              <Spacer {...planElementSpacerProps}>
                <InternetDescription
                  plan={plan}
                  uploadFootnote={footnoteUpload}
                  expandable
                  disabled={isDisabled}
                />
              </Spacer>
            )}
            {plan.telephony && (
              <Spacer {...planElementSpacerProps}>
                <TelephonyDescription
                  plan={plan}
                  expandable
                  disabled={isDisabled}
                  telephonyFootnote={footnoteTelefonFlat}
                  {...(dynamicPlan && {
                    checked:
                      modifier !== 'withoutPhone' && modifier !== 'withoutAll',
                    onToggle: onPhoneToggle,
                  })}
                />
              </Spacer>
            )}
            {plan.tv && (
              <Spacer {...planElementSpacerProps}>
                <NetTVDescription
                  plan={plan}
                  expandable
                  disabled={isDisabled}
                  netTvFootnote={footnoteNetTV}
                  {...(dynamicPlan && {
                    checked:
                      modifier !== ProductModifiers.withoutTv &&
                      modifier !== ProductModifiers.withoutAll,
                    onToggle: onNetTvToggle,
                  })}
                />
              </Spacer>
            )}
            {usedPlan.groups.includes(ProductGroups.FIBER) && (
              <Spacer x={2} block className={isDisabled && styles.disabled}>
                <Spacer y={2} block>
                  <Separator color="superlight-grey" height={4} />
                </Spacer>
                <NetServiceInfo
                  isIncluded={usedPlan.items?.some(
                    (item) => item.type === 'NET_SERVICE',
                  )}
                  disabled={isDisabled}
                />
                <Spacer t={1} />
                <TreeInfo disabled={isDisabled} />
              </Spacer>
            )}
            {plan.iadDeviceInfo && (
              <Spacer x={2} block>
                <Spacer y={2} block>
                  <Separator color="superlight-grey" height={4} />
                </Spacer>
                <IadDevice
                  {...plan.iadDeviceInfo}
                  footnoteFlag={footnoteRouterFlag}
                  disabled={isDisabled}
                />
              </Spacer>
            )}
            {plan.templatePageLink && (
              <>
                <Spacer y={2} x={2} block>
                  <Separator color="superlight-grey" height={4} />
                </Spacer>
                <Spacer y={2} x={2} block>
                  <TemplatePageLink
                    disabled={isDisabled}
                    url={`${plan.templatePageLink}${
                      modifier ? '#' + modifier : ''
                    }`}
                  />
                </Spacer>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PlanColumn;
